import React from 'react';
import ProductPageTemplate from '../../components/ProductPageTemplate'

import schiebetuer_00 from '../../images/schiebetuer_00_1600.webp'
import schiebetuer_01 from '../../images/schiebetuer_01_1600.webp'
import schiebetuer_02 from '../../images/schiebetuer_02_1600.webp'
import schiebetuer_03 from '../../images/schiebetuer_03_1600.webp'
import schiebetuer_04 from '../../images/schiebetuer_04_1600.webp'
import schiebetuer_05 from '../../images/schiebetuer_05_1600.webp'
import schiebetuer_thumb_00 from '../../images/schiebetuer_00_400.webp'
import schiebetuer_thumb_01 from '../../images/schiebetuer_01_400.webp'
import schiebetuer_thumb_02 from '../../images/schiebetuer_02_400.webp'
import schiebetuer_thumb_03 from '../../images/schiebetuer_03_400.webp'
import schiebetuer_thumb_04 from '../../images/schiebetuer_04_400.webp'
import schiebetuer_thumb_05 from '../../images/schiebetuer_05_400.webp'

const context = {
  title: 'Automatische Schiebetür',
  description: 'Öffnet leise, berührungslos und lässt sich in jede Umgebung intergrieren ',
  text: 'Eine automatische Schiebetüre besteht aus ein bis zwei sich öffnenden Türflügeln sowie einer Antriebs- und Sensorikeinheit. Sie kann beispielsweise in ein Rahmenelement (Festverglasung) oder in ein Mauerwerk eingefasst werden. Die Einbaumaße dieser Automatiktür bewegen sich meist in einer lichten Einbaubreite von 800 mm bis 3000 mm. \n\n' +
      'Die Automatiktüren ermöglichen es, Eingangssituationen barrierefrei, hygienisch und zeitgemäß zu gestalten. Sie lassen sich durch ihre leichte und elegante Bauweise in fast allen Neu- und Umbauten einfügen. Zudem können automatische Schiebetüren unter Beachtung weiterer Bestimmungen auch im Bereich eines Flucht- und Rettungswegs verbaut werden. Automatikschiebetüren sind daher die häufigste Lösung für gewerbliche und öffentliche Eingänge. \n\n' +
      'Nicht von der Stange -  jede Automatiktür ist eine individuell angepasste Lösung. Denn erst wenn das Produkt und die örtlichen Gegebenheiten ineinandergreifen kann eine langfristig zufriedenstellende Eingangssituation entstehen. Um diese nach Ihren Vorstellungen und Wünschen technisch und optisch perfekt zu gestalten, legen wir von Automatiktür24 größten Wert auf eine smarte Beratung und kompetente Umsetzung.',
  features: [
    //'autschr',
    'barrierefreiheit',
    //'brandschutz',
    //'breakOut',
    //'design',
    //'din18650',
    //'energieeinsparung',
    'modernisierung',
    'notStrom',
    'personenfrequenz',
    //'platzsparend',
    //'transparenz',
    //'einbruchhemmung',
    'zutrittskontrolle',
    'fluchtweg',
  ],
  legalRequirements: [
    'autschr',
    'din18650',
    //'dinEn170',
    //'eltvtr',
  ],
  carouselImages: [
    {
      original: schiebetuer_00,
      thumbnail: schiebetuer_thumb_00,
      originalAlt: 'Automatische Schiebetür Supermarkt'
    },
    {
      original: schiebetuer_01,
      thumbnail: schiebetuer_thumb_01,
      originalAlt: 'Automatische Schiebetür Bürogebäude'
    },
    {
      original: schiebetuer_02,
      thumbnail: schiebetuer_thumb_02,
      originalAlt: 'Automatische Schiebetür Bank'
    },
    {
      original: schiebetuer_03,
      thumbnail: schiebetuer_thumb_03,
      originalAlt: 'Automatische Schiebetür Indoor'
    },
    {
      original: schiebetuer_04,
      thumbnail: schiebetuer_thumb_04,
      originalAlt: 'Automatische Schiebetür extra-hoch'
    },
    {
      original: schiebetuer_05,
      thumbnail: schiebetuer_thumb_05,
      originalAlt: 'Automatische Schiebetür Apotheke'
    },
  ],
  configurator: true,
}

const Page = () => {

  return(
    <ProductPageTemplate pageContext={context} />
  )
}

export default Page;
